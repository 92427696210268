<template>
  <div style='display: flex; align-items: center'>
    <svg v-if="name === 'TruckRight' && variant === 'light'" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path clip-rule="evenodd"
        d="M8.97 17.182a1.917 1.917 0 1 0-3.834 0 1.917 1.917 0 0 0 3.833 0m9.146 0a1.917 1.917 0 1 0-3.834 0 1.917 1.917 0 0 0 3.834 0"
        :stroke='strokeColor' stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M16.197 15.266V6.98a2.08 2.08 0 0 0-2.08-2.08H5.049A2.05 2.05 0 0 0 3 6.95v8.155a2.08 2.08 0 0 0 2.08 2.08m3.889-.002h5.31m3.834 0h.808A2.08 2.08 0 0 0 21 15.103V11.66c0-.936-.553-1.784-1.41-2.162l-3.393-1.497m2.401 4.409h2.4"
        :stroke='strokeColor' stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="m10.484 11.878 1.582-1.578-1.582-1.575m1.583 1.576H7.129" :stroke='strokeColor' stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="name === 'TruckRight' && variant === 'bold'" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M18.92 16.43h-.16a2.66 2.66 0 0 0-1.81-1.8V9.15l2.35 1.04c.58.25.95.83.95 1.47H18.6a.749.749 0 1 0 0 1.5h1.65v1.94c0 .74-.59 1.33-1.33 1.33m-3.85 1.05a1 1 0 0 1-.039-.29l.001-.006-.001-.01c.001-.357.16-.665.419-.874.2-.18.47-.28.75-.28s.55.1.75.28c.26.21.42.52.42.88a1.17 1.17 0 0 1-2.3.3m-9.18-.28v-.02c0-.64.52-1.16 1.17-1.16.64 0 1.16.52 1.16 1.16 0 .03 0 .06-.01.09a1.15 1.15 0 0 1-1.15 1.08c-.64 0-1.16-.51-1.17-1.15m3.75-5.85.3-.3H6.82c-.42 0-.75-.33-.75-.75 0-.41.33-.75.75-.75h3.12l-.3-.3a.754.754 0 0 1 0-1.06c.29-.29.77-.29 1.06 0l1.48 1.49c.03.02.07.05.1.09a.6.6 0 0 1 .21.39c.01.03.02.05.01.07-.002-.023 0-.045-.01-.07.01.04.01.09.01.14s0 .1-.01.15c-.03.15-.1.29-.21.39l-1.58 1.58c-.14.14-.34.21-.53.21s-.38-.07-.53-.22a.754.754 0 0 1 0-1.06M19.9 8.82l-2.95-1.3v-.54c0-1.56-1.27-2.83-2.83-2.83H5.05c-1.54 0-2.8 1.26-2.8 2.8v8.15c0 1.35.96 2.49 2.23 2.76a2.664 2.664 0 0 0 2.58 1.99 2.654 2.654 0 0 0 2.548-1.916h4.034a2.666 2.666 0 0 0 5.118-.004h.16c1.56 0 2.83-1.27 2.83-2.83v-3.44c0-1.24-.73-2.36-1.85-2.84"
        fill-rule="evenodd" clip-rule="evenodd" fill="#121212" />
    </svg>

    <svg v-if="name === 'Contacts_3' && variant === 'light'" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M17.888 10.897a2.875 2.875 0 0 0 2.472-2.841 2.875 2.875 0 0 0-2.406-2.836m1.775 9.03c1.35.203 2.294.675 2.294 1.65 0 .672-.445 1.108-1.163 1.382"
        :stroke='strokeColor' stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path clip-rule="evenodd"
        d="M11.887 14.664c-3.214 0-5.96.487-5.96 2.432s2.729 2.445 5.96 2.445c3.214 0 5.958-.482 5.958-2.428s-2.727-2.45-5.958-2.45m0-2.775a3.819 3.819 0 1 0-3.82-3.819 3.804 3.804 0 0 0 3.792 3.819z"
        :stroke='strokeColor' stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M5.885 10.897a2.875 2.875 0 0 1-2.472-2.841A2.875 2.875 0 0 1 5.819 5.22m-1.775 9.03c-1.351.203-2.294.675-2.294 1.65 0 .672.444 1.108 1.162 1.382"
        :stroke='strokeColor' stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="name === 'Contacts_3' && variant === 'bold'" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M11.949 14.54c3.431 0 6.361.544 6.361 2.72S15.399 20 11.949 20c-3.431 0-6.36-.544-6.36-2.72s2.91-2.74 6.36-2.74m5.486-1.311c1.312-.024 2.722.156 3.243.284 1.104.217 1.83.66 2.131 1.304a1.92 1.92 0 0 1 0 1.67c-.46.998-1.944 1.319-2.52 1.402-.12.018-.215-.086-.203-.206.295-2.767-2.048-4.08-2.654-4.381-.026-.014-.032-.034-.03-.047a.04.04 0 0 1 .033-.026m-10.87 0c.02.003.03.017.032.026.003.013-.003.033-.028.047-.607.302-2.95 1.614-2.656 4.38.013.121-.082.224-.201.207-.577-.083-2.06-.404-2.52-1.402a1.92 1.92 0 0 1 0-1.67c.3-.644 1.026-1.087 2.13-1.305.522-.127 1.93-.307 3.244-.283M11.95 4a4.206 4.206 0 0 1 4.21 4.233 4.207 4.207 0 0 1-4.21 4.234 4.207 4.207 0 0 1-4.21-4.234A4.206 4.206 0 0 1 11.95 4m5.714.706c2.257 0 4.029 2.135 3.425 4.514-.407 1.601-1.882 2.665-3.525 2.621a3.4 3.4 0 0 1-.483-.046.158.158 0 0 1-.107-.245 5.77 5.77 0 0 0 .984-3.24 5.8 5.8 0 0 0-1.068-3.372c-.022-.03-.038-.075-.016-.11.018-.028.05-.042.082-.05q.342-.07.708-.072m-11.327 0q.366.001.71.073c.03.007.064.022.081.05.021.034.006.08-.016.11a5.8 5.8 0 0 0-1.068 3.37 5.76 5.76 0 0 0 .984 3.241.158.158 0 0 1-.106.244 3 3 0 0 1-.484.047c-1.643.043-3.118-1.02-3.525-2.621-.605-2.38 1.167-4.514 3.423-4.514"
        fill="#121212" />
    </svg>

    <svg v-if="name === 'Pin13' && variant === 'light'" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M5.053 10.64c0 4.944 5.835 9.668 6.947 9.668s6.947-4.724 6.947-9.668a6.947 6.947 0 1 0-13.894 0"
        :stroke='strokeColor' stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M14.316 10.584a2.315 2.315 0 1 0-4.63 0 2.315 2.315 0 0 0 4.63 0" clip-rule="evenodd" :stroke='strokeColor'
        stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="name === 'Pin13' && variant === 'bold'" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 13.13a2.55 2.55 0 0 1-2.547-2.546A2.55 2.55 0 0 1 12 8.037a2.55 2.55 0 0 1 2.546 2.547A2.55 2.55 0 0 1 12 13.13m0-9.9c-4.085 0-7.409 3.325-7.409 7.41 0 5.203 6.085 10.13 7.409 10.13s7.409-4.927 7.409-10.13c0-4.085-3.325-7.41-7.41-7.41"
        fill-rule="evenodd" clip-rule="evenodd" fill="#121212" />
    </svg>

    <svg v-if="name === 'BankCardDouble' && variant === 'light'" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M18.438 7.586H9.386c-2.189 0-3.562 1.55-3.562 3.745v5.92c0 2.195 1.366 3.745 3.562 3.745h9.05c2.198 0 3.564-1.55 3.564-3.745v-5.92c0-2.195-1.366-3.745-3.562-3.745"
        clip-rule="evenodd" :stroke='strokeColor' stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M9.372 12.048h9.075m-.362-4.464L16.5 4.94c-1.136-1.872-3.101-2.503-4.987-1.366L3.762 8.24c-1.878 1.128-2.256 3.164-1.128 5.05l3.062 5.066q.212.367.48.664v.007"
        :stroke='strokeColor' stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="name === 'BankCardDouble' && variant === 'bold'" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M4.562 11.6c0-3.091 2.082-5.25 5.062-5.25h7.698a.3.3 0 0 0 .257-.455l-.888-1.478c-1.315-2.165-3.595-2.784-5.673-1.538L3.27 7.549C1.191 8.8.668 11.104 1.972 13.287l2.034 3.324c.158.259.556.147.556-.156z"
        fill="#121212" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M18.686 13.07H9.61a.75.75 0 0 1 0-1.5h9.075a.75.75 0 0 1 0 1.5m-.01-5.72H9.624c-2.43 0-4.062 1.708-4.062 4.25v5.92c0 2.536 1.633 4.24 4.062 4.24h9.05c2.43 0 4.064-1.704 4.064-4.24V11.6c0-2.542-1.633-4.25-4.062-4.25"
        fill="#121212" />
    </svg>

    <svg v-if="name === 'BoardTasks' && variant === 'light'" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M7.782 3h8.435C19.165 3 21 5.081 21 8.026v7.948C21 18.919 19.165 21 16.216 21H7.782C4.834 21 3 18.919 3 15.974V8.026C3 5.081 4.843 3 7.782 3m4.925 12.305h4.31"
        :stroke='strokeColor' stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="m7.467 14.6.823.823 1.603-1.603M7.467 9.357l.823.824 1.603-1.603m2.814 1.488h4.31" :stroke='strokeColor'
        stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="name === 'BoardTasks' && variant === 'bold'" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M17.017 10.816h-4.31a.75.75 0 0 1 0-1.5h4.31a.75.75 0 0 1 0 1.5m0 5.239h-4.31a.75.75 0 0 1 0-1.5h4.31a.75.75 0 0 1 0 1.5m-6.593-6.947-1.603 1.604a.754.754 0 0 1-1.061-.001l-.824-.824a.751.751 0 0 1 1.062-1.06l.293.293 1.072-1.072a.75.75 0 0 1 1.061 1.06m0 5.242-1.603 1.603a.75.75 0 0 1-1.061 0l-.823-.823a.749.749 0 1 1 1.06-1.06l.293.292 1.073-1.072a.75.75 0 0 1 1.061 1.06M16.217 2.5H7.783C4.623 2.5 2.5 4.721 2.5 8.026v7.948c0 3.305 2.123 5.526 5.283 5.526h8.433c3.16 0 5.284-2.221 5.284-5.526V8.026c0-3.305-2.123-5.526-5.283-5.526"
        fill="#121212" />
    </svg>

    <svg v-if="name === 'UserCircle' && variant === 'light'" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M12 4.385a7.616 7.616 0 1 0 0 15.23 7.616 7.616 0 0 0 0-15.23M3 12a9 9 0 0 1 9-9 9 9 0 1 1-9 9"
        fill="#121212" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M8.895 17.259c-.602.47-.888 1.075-.96 1.637a.692.692 0 1 1-1.374-.177c.117-.902.576-1.843 1.48-2.55s2.2-1.144 3.931-1.144c1.749 0 3.053.439 3.958 1.151a3.85 3.85 0 0 1 1.472 2.575.692.692 0 1 1-1.375.166 2.47 2.47 0 0 0-.953-1.653c-.604-.475-1.581-.854-3.102-.854-1.501 0-2.473.376-3.077.849m3.079-9.153a2.35 2.35 0 1 0 0 4.7 2.35 2.35 0 0 0 0-4.7m-3.735 2.35a3.735 3.735 0 1 1 7.47 0 3.735 3.735 0 0 1-7.47 0"
        fill="#121212" />
    </svg>
    <svg v-if="name === 'UserCircle' && variant === 'bold'" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12.0201 14.4752C13.7158 14.4752 15.0939 13.0961 15.0939 11.4004C15.0939 9.70476 13.7158 8.32568 12.0201 8.32568C10.3244 8.32568 8.94629 9.70476 8.94629 11.4004C8.94629 13.0961 10.3244 14.4752 12.0201 14.4752Z"
        fill="#121212" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M12 21C7.03754 21 3 16.9625 3 12C3 7.03754 7.03754 3 12 3C16.9625 3 21 7.03754 21 12C21 16.9625 16.9625 21 12 21ZM12 4.38462C7.80092 4.38462 4.38462 7.80092 4.38462 12C4.38462 14.4606 5.55779 16.6526 7.37418 18.0457C7.90591 16.6743 9.39927 15.5444 12.0201 15.5444C14.6304 15.5444 16.1216 16.6612 16.6588 18.0202C18.4564 16.626 19.6154 14.4458 19.6154 12C19.6154 7.80092 16.1991 4.38462 12 4.38462Z"
        fill="#121212" />
    </svg>

    <svg v-if="name === 'Logout' && variant === 'light'" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M15.016 7.39v-.934a3.685 3.685 0 0 0-3.685-3.685H6.456a3.685 3.685 0 0 0-3.684 3.685v11.13a3.685 3.685 0 0 0 3.684 3.686h4.885a3.675 3.675 0 0 0 3.675-3.674v-.944m6.794-4.632H9.769m9.112-2.916 2.928 2.915-2.928 2.916"
        :stroke='strokeColor' stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="name === 'Logout' && variant === 'bold'" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M11.493 2C13.975 2 16 3.99 16 6.44v4.79H9.895a.77.77 0 0 0-.783.77c0 .42.346.77.783.77H16v4.78C16 20 13.975 22 11.472 22H6.517C4.025 22 2 20.01 2 17.56V6.45C2 3.99 4.035 2 6.528 2zm7.047 6.55c.3-.31.79-.31 1.09-.01l2.92 2.91a.764.764 0 0 1 0 1.09l-2.92 2.91c-.15.15-.35.23-.54.23a.773.773 0 0 1-.55-1.32l1.6-1.59H16v-1.54h4.14l-1.6-1.59c-.3-.3-.3-.79 0-1.09"
        fill="#121212" />
    </svg>

    <svg v-if="name === 'arrow-left' && variant === 'light'" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path d="m15.5 19.548-7-7 7-7" :stroke='strokeColor' stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>

    <svg v-if="name === 'Hamburger' && variant === 'light'" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path d="M4.82 18.72h15.36M4.82 5.28h15.36M4.82 12h15.36" :stroke='strokeColor' stroke-width="2" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>

    <svg v-if="name === 'Calculate' && variant === 'light'" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M7.936 3.692h6.804c2.582 0 4.188 1.823 4.188 4.402v7.812c0 2.58-1.606 4.402-4.189 4.402H7.936c-2.581 0-4.188-1.823-4.188-4.402V8.094c0-2.58 1.614-4.402 4.188-4.402"
        :stroke='strokeColor' stroke-width="1.385" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M7.316 13.414v.009m0 3.452v.01m3.923-3.471v.009m0 3.452v.01m3.924-3.471v.009m0 3.452v.01M7.34 13.32a.116.116 0 0 1 .113.138.115.115 0 0 1-.158.084.115.115 0 0 1-.062-.151.12.12 0 0 1 .063-.063.1.1 0 0 1 .044-.008m0 3.461a.116.116 0 0 1 .113.139.12.12 0 0 1-.091.09.115.115 0 0 1-.13-.158.12.12 0 0 1 .108-.07m3.923-3.461a.115.115 0 1 1-.001.23.115.115 0 0 1 .001-.23m0 3.461a.115.115 0 1 1 0 .231.115.115 0 0 1 0-.23m3.923-3.461a.115.115 0 1 1-.001.23.115.115 0 0 1 .001-.23m0 3.461a.115.115 0 1 1 0 .231.115.115 0 0 1 0-.23"
        :stroke='strokeColor' stroke-width="1.385" stroke-linecap="round" stroke-linejoin="round" />
      <path clip-rule="evenodd"
        d="M14.31 6.83H8.37c-.928 0-1.506.518-1.506 1.471v.268c0 .968.578 1.499 1.506 1.499h5.937c.93 0 1.506-.52 1.506-1.475v-.292c.002-.953-.573-1.472-1.504-1.472"
        :stroke='strokeColor' stroke-width="1.385" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <svg v-if="name === 'Plus4' && variant === 'light'" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M6 12h12m-6-6v12" :stroke='strokeColor' stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <svg v-if="name === 'Down-Line' && variant === 'light'" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M11.999 15.522V5m4.339 6.168-4.34 4.354-4.336-4.354M6.736 19h10.526" :stroke='strokeColor' stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <svg v-if="name === 'Profile' && variant === 'light'" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path clip-rule="evenodd"
        d="M9.987 12.789c-3.223 0-5.975.487-5.975 2.438s2.735 2.457 5.975 2.457c3.223 0 5.975-.488 5.975-2.44 0-1.95-2.734-2.455-5.975-2.455m0-2.784a3.83 3.83 0 1 0-3.83-3.83 3.817 3.817 0 0 0 3.803 3.83z"
        :stroke='strokeColor' stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <svg v-if="name === 'Delivery-Box' && variant === 'light'" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M16.125 7.47v5.045c0 .669-.357 1.287-.936 1.62l-4.369 2.523a1.87 1.87 0 0 1-1.871 0L4.58 14.136a1.87 1.87 0 0 1-.935-1.62V7.47c0-.67.357-1.287.935-1.621l4.37-2.521a1.88 1.88 0 0 1 1.871 0l4.37 2.522c.578.334.935.952.935 1.62"
        :stroke='strokeColor' stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M3.903 6.511 9.882 9.97l5.978-3.46M9.886 16.923V9.965" :stroke='strokeColor' stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <svg v-if="name === 'Ruler' && variant === 'light'" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path clip-rule="evenodd"
        d="m8.753 16.977 7.473-7.474a2.466 2.466 0 0 0 0-3.488l-1.992-1.993a2.47 2.47 0 0 0-3.49 0l-7.472 7.474a2.466 2.466 0 0 0 0 3.488l1.991 1.993a2.467 2.467 0 0 0 3.49 0"
        :stroke='strokeColor' stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="m10.295 4.47 2.467 2.467m-9.043 4.112 1.918 1.918m.301-4.137 2.44 2.492m-.274-4.659 1.37 1.37"
        :stroke='strokeColor' stroke-width="1.5" stroke-linecap="round" />
    </svg>

    <svg v-if="name === 'Coins-2' && variant === 'light'" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M15.436 12.57c0 .99-1.555 1.794-3.474 1.794s-3.474-.804-3.474-1.794" :stroke='strokeColor' stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round" />
      <path d="M15.436 9.434v6.273c0 .99-1.555 1.793-3.474 1.793s-3.474-.803-3.474-1.793V9.434" :stroke='strokeColor'
        stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path clip-rule="evenodd"
        d="M15.436 9.433c0 .99-1.555 1.794-3.474 1.794s-3.474-.804-3.474-1.794 1.555-1.793 3.474-1.793 3.474.802 3.474 1.793"
        :stroke='strokeColor' stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8.038 9.223c-1.919 0-3.475-.803-3.475-1.793m3.475 4.93c-1.919 0-3.475-.803-3.475-1.794" :stroke='strokeColor'
        stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M4.564 4.294v9.41c0 .99 1.555 1.793 3.474 1.793m3.474-11.203v3.347" :stroke='strokeColor' stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round" />
      <path clip-rule="evenodd"
        d="M11.511 4.294c0 .99-1.555 1.793-3.474 1.793-1.918 0-3.474-.803-3.474-1.793 0-.991 1.556-1.794 3.474-1.794 1.92 0 3.474.803 3.474 1.794"
        :stroke='strokeColor' stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <svg v-if="name === 'Replay-2' && variant === 'light'" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M9.697 12.802 7.5 14.998l2.197 2.196m.608-9.999L12.5 5l-2.196-2.196" :stroke='strokeColor' stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M12.5 5h-5a5 5 0 0 0-5 5c0 1.593.748 3.01 1.908 3.925M7.5 15h5a5 5 0 0 0 5-5c0-1.594-.747-3.01-1.908-3.926"
        :stroke='strokeColor' stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <svg v-if="name === 'Truck-2' && variant === 'light'" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path d="M4.28 14.82h-.047A1.733 1.733 0 0 1 2.5 13.086v-2.094" :stroke='strokeColor' stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round" />
      <path clip-rule="evenodd"
        d="M7.475 14.82a1.597 1.597 0 1 0-3.195 0 1.597 1.597 0 0 0 3.195 0m7.619 0a1.597 1.597 0 1 0-3.195 0 1.597 1.597 0 0 0 3.195 0"
        :stroke='strokeColor' stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M7.474 14.82h4.425m1.598-1.598V6.316c0-.957-.775-1.732-1.732-1.732H2.5m0 4.016h3.71M4.072 6.592H6.21m8.885 8.228h.672c.957 0 1.733-.777 1.733-1.733v-2.87c0-.78-.46-1.487-1.174-1.801l-2.828-1.248m2 3.675h2.001"
        :stroke='strokeColor' stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <svg v-if="name === 'Edit-Square' && variant === 'light'" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M9.577 2.324H6.461c-2.562 0-4.169 1.814-4.169 4.383v6.928c0 2.568 1.6 4.383 4.17 4.383h7.353c2.57 0 4.17-1.815 4.17-4.383v-3.357"
        :stroke='strokeColor' stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path clip-rule="evenodd"
        d="m7.357 9.1 6.227-6.227a1.99 1.99 0 0 1 2.81 0l1.013 1.014a1.986 1.986 0 0 1 0 2.81l-6.257 6.257c-.34.34-.8.53-1.28.53H6.75l.078-3.15c.012-.463.201-.905.53-1.233"
        :stroke='strokeColor' stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="m12.638 3.835 3.805 3.805" :stroke='strokeColor' stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>

    <svg v-if="name === 'Arrow-Right-2' && variant === 'light'" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M7.97 19.53a.75.75 0 0 1-.073-.976l.073-.084L14.439 12l-6.47-6.47a.75.75 0 0 1-.072-.976l.073-.084a.75.75 0 0 1 .976-.073l.084.073 7 7a.75.75 0 0 1 .073.976l-.073.084-7 7a.75.75 0 0 1-1.06 0"
        fill="#121212" />
    </svg>

    <svg v-if="name === 'Receipt-bill' && variant === 'light'" width="24" height="24" viewBox="0 0 24 24" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.247 14.06h-5.4m5.4-4.195h-5.4M8.218 5.276V17.82a2.604 2.604 0 0 1-2.605 2.604h12.138c2.084 0 3.24-1.403 3.24-3.672L21 5.276c0-.83-.794-1.369-1.483-1.016l-.653.345a1.43 1.43 0 0 1-1.245.035l-1.845-.82a2.9 2.9 0 0 0-2.33 0l-1.846.82a1.43 1.43 0 0 1-1.244-.035L9.7 4.26c-.69-.353-1.483.195-1.483 1.016"
        :stroke='strokeColor' stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M5.613 20.424a2.6 2.6 0 0 1-1.844-.759A2.6 2.6 0 0 1 3 17.821v-7.248c0-.831.795-1.37 1.484-1.016l.653.344c.388.204.838.213 1.244.036l1.245-.556c.097-.045.344.07.592.202"
        :stroke='strokeColor' stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <svg v-if="name === 'Discount-ticket' && variant === 'light'" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path
        d="m9.625 14.452 4.897-4.897m-.122 4.818v.048m.195-.037a.196.196 0 1 1-.392 0 .196.196 0 0 1 .392 0M9.637 9.608v.048m.195-.038a.196.196 0 1 1-.393 0 .196.196 0 0 1 .393 0"
        :stroke='strokeColor' stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M19.039 11.998a1.88 1.88 0 0 1 1.019-1.681c.412-.216.619-.324.674-.369.192-.154.206-.178.254-.42.014-.07.014-.186.014-.419 0-1.084 0-1.627-.128-2.07a3.24 3.24 0 0 0-2.208-2.207c-.443-.129-.985-.129-2.07-.129H7.407c-1.082 0-1.624 0-2.065.128A3.24 3.24 0 0 0 3.13 7.044c-.127.442-.127.984-.126 2.066.001.232.001.347.014.416.048.242.062.265.254.42.055.044.26.153.671.37.606.32 1.017.943 1.016 1.683v.058a1.9 1.9 0 0 1-.985 1.597c-.44.241-.66.362-.714.406-.194.162-.199.169-.247.417-.013.068-.013.185-.013.42 0 1.08 0 1.62.127 2.06a3.24 3.24 0 0 0 2.214 2.214c.44.127.98.127 2.06.127h9.193c1.084 0 1.626 0 2.069-.128a3.24 3.24 0 0 0 2.208-2.21c.129-.442.129-.984.128-2.068 0-.233 0-.349-.013-.418-.048-.242-.062-.265-.254-.42-.055-.045-.261-.153-.674-.371a1.89 1.89 0 0 1-1.019-1.685"
        :stroke='strokeColor' stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <svg v-if="name === 'X' && variant === 'light'" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path d="m9.01 22.99 6.991-6.991 6.991 6.99m0-13.981L16 15.998l-6.99-6.99" :stroke='strokeColor' stroke-width="2.667"
        stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <svg v-if="name === 'Copy-2' && variant === 'light'" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M12.737 4.79c-.212-1.367-1.196-2.29-2.68-2.29H5.245c-1.675 0-2.727 1.19-2.727 2.87v4.533c0 1.531.875 2.66 2.31 2.838"
        :stroke='strokeColor' stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path clip-rule="evenodd"
        d="M14.755 7.232h-4.81c-1.677 0-2.728 1.187-2.728 2.867v4.533c0 1.68 1.046 2.866 2.727 2.866h4.81c1.683 0 2.729-1.186 2.729-2.867V10.1c0-1.68-1.046-2.867-2.728-2.867"
        :stroke='strokeColor' stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <svg v-if="name === 'Menu-1' && variant === 'light'" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        d="M12.014 18.522h-.064m.05.26a.26.26 0 1 1 0-.522.26.26 0 0 1 0 .521m.014-6.282h-.064m.05.259a.26.26 0 1 1 0-.522.26.26 0 0 1 0 .522m.014-6.278h-.064m.05.258a.26.26 0 1 1 0-.521.26.26 0 0 1 0 .521"
        :stroke='strokeColor' stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <svg v-if="name === 'Bank-card' && variant === 'light'" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path clip-rule="evenodd" d="M17.035 4.54H6.964C4.528 4.54 3 6.263 3 8.704v6.588c0 2.442 1.52 4.167 3.964 4.167h10.07c2.445 0 3.966-1.725 3.966-4.167V8.705c0-2.44-1.52-4.166-3.965-4.166" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3 9.834h18M7.177 15.17h2.968" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

  </div>
</template>

<script>

export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    variant: {
      type: String,
      default: 'light'
    },
    strokeColor: {
      type: String,
      default: '#121212'
    }
  },
}
</script>

<style lang='scss'></style>